import { gql } from "overmind-graphql";

// @ts-ignore
export const getOperators = gql`
  query getOperators {
    operators(where: {}) {
      created_at
      email
      id
      name
      updated_at
      otp_secret
    }
  }
`;
