import { gql } from "overmind-graphql";

// @ts-ignore
export const getMessages = gql`
  query getMessages($communityId: uuid) {
    messages(where: { community_id: { _eq: $communityId } }) {
      community_id
      created_at
      id
      message
      operator_id
    }
  }
`;
