// @ts-ignore
import { gql } from "overmind-graphql";

// @ts-ignore
export const createMessage = gql`
  mutation create($communityId: uuid, $message: jsonb) {
    insert_messages_one(
      object: { community_id: $communityId, message: $message }
    ) {
      id
    }
  }
`;

// @ts-ignore
export const deleteMessage = gql`
  mutation deleteMessage($messageId: uuid!) {
    delete_messages_by_pk(id: $messageId) {
      id
    }
  }
`;
