import { gql } from "overmind-graphql";

// @ts-ignore
export const list = gql`
  query list {
    communities {
      id
      created_at
      description
      name
      operator_id
    }
  }
`;
