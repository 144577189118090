// @ts-ignore
import { gql } from "overmind-graphql";

// @ts-ignore
export const create = gql`
  mutation create($description: String, $name: String) {
    insert_communities_one(object: { description: $description, name: $name }) {
      id
      created_at
      description
      name
    }
  }
`;
// @ts-ignore
export const assignOperator = gql`
  mutation assignOperator($communityId: uuid!, $operatorId: uuid!) {
    update_communities_by_pk(
      pk_columns: { id: $communityId }
      _set: { operator_id: $operatorId }
    ) {
      operator_id
      name
      id
      description
      created_at
    }
  }
`;
// @ts-ignore
export const assignOperatorCom = gql`
  mutation assignOperatorCom($communityId: uuid!, $operatorId: uuid!) {
    update_operators(
      where: { id: { _eq: $operatorId } }
      _set: { assigned_community_id: $communityId }
    ) {
      returning {
        id
      }
    }
  }
`;
// @ts-ignore
export const deleteCommunity = gql`
  mutation MyMutation($communityId: uuid!) {
    delete_communities_by_pk(id: $communityId) {
      id
    }
  }
`;
