// @ts-ignore
import { gql } from "overmind-graphql";

// @ts-ignore
export const createNewOperator = gql`
  mutation createNewOperator($name: String, $email: String) {
    insert_operators_one(object: { name: $name, email: $email }) {
      created_at
      email
      id
      name
      otp_secret
      updated_at
    }
  }
`;

// @ts-ignore
export const deleteOperator = gql`
  mutation deleteOperator($email: String!) {
    delete_operators_by_pk(email: $email) {
      id
    }
  }
`;
